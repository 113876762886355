import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes } from 'react-router-dom';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';
import { LoadingSpinnerContainer } from 'src/components/LoadingSpinner/LoadingSpinnerContainer';
import { getSite } from 'src/frameworks/GetSite';
import { SiteType } from 'src/frameworks/models/SiteType';
import DojaCat from 'src/sites/DojaCatInternalPilot/DojaCat';
import Plumeria from 'src/sites/Plumeria/Plumeria';
import TormentedValley from 'src/sites/TormentedValleyExternalPilot/TormentedValley';
import { getClientId, selectCsrfToken, setAuthState } from 'src/store/authSlice';
import { setCart } from 'src/store/cartSlice';
import { setIdentifierState } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';
import { setVariationIndexes } from 'src/store/variationSlice';
import { v4 as uuidv4 } from 'uuid';

export interface SiteProps {
  siteType: SiteType;
  sitePrefix: string;
  includeSessionIdInMetrics: boolean;
}

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const site = getSite()?.type;
  const rootSelector = `site-${site}`;
  document.documentElement.setAttribute('site-id', rootSelector);
  const [isLoaded, setIsLoaded] = useState(false);
  const csrfToken = useSelector(selectCsrfToken);

  // read from local storage and set redux store on load/reload
  useEffect(() => {
    const storedAuthStateJSON = localStorage.getItem('authState');
    const storedAuthState = storedAuthStateJSON ? JSON.parse(storedAuthStateJSON) : null;
    if (storedAuthState) {
      dispatch(setAuthState(storedAuthState));
    }

    const storedCartStateJSON = localStorage.getItem('cartState');
    const storedCartState = storedCartStateJSON ? JSON.parse(storedCartStateJSON) : null;
    if (storedCartState) {
      dispatch(setCart({ cart: storedCartState }));
    }

    const storedVariationStateJSON = localStorage.getItem('variationState');
    const storedVariationState = storedVariationStateJSON
      ? JSON.parse(storedVariationStateJSON)
      : null;
    if (storedVariationState) {
      dispatch(setVariationIndexes({ selectedVariation: storedVariationState }));
    }
    setIsLoaded(true);
    const storedSessionId = localStorage.getItem('sessionId');
    if (!storedSessionId) {
      const sessionId = uuidv4();
      localStorage.setItem('sessionId', sessionId);
      dispatch(setIdentifierState({ sessionId: sessionId }));
    }
    if (storedSessionId) {
      dispatch(setIdentifierState({ sessionId: storedSessionId }));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getClientId({
        site: site,
        csrfToken: csrfToken,
      }),
    );
  }, [dispatch, csrfToken]);

  if (!isLoaded) {
    return (
      <div className="full-page-wrapper">
        <LoadingSpinnerContainer data-testid="loadingSpinnerContainer">
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </div>
    );
  } else if (site === SiteType.DOJACAT) {
    return <DojaCat />;
  } else if (site === SiteType.TORMENTEDVALLEY) {
    return <TormentedValley />;
  } else if (site === SiteType.PLUMERIA) {
    return <Plumeria />;
  }

  return (
    <>
      <BrowserRouter>
        <Routes></Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
