import 'src/sites/DojaCatInternalPilot/components/MerchGrid/MerchGridItem.scss';

import { ItemMetadata, MerchItem } from '@amzn/mulberry-external-typescript-client';
import MerchGrid from 'src/components/MerchGrid/MerchGrid';
import MessageBox from 'src/components/MessageBox/MessageBox';
import { DOJA_CAT_ENGAGEMENTS } from 'src/constants/roadie';
import { GENERIC_ERROR_STRING, UH_OH } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import withPageMetrics from 'src/shared/withPageMetrics';
import Footer from 'src/sites/DojaCatInternalPilot/components/Footer/Footer';
import { merchGridCss } from 'src/sites/DojaCatInternalPilot/components/MerchGrid/MerchGridCss';
import MerchGridItem from 'src/sites/DojaCatInternalPilot/components/MerchGrid/MerchGridItem';
import { confirmOrderErrorCss } from 'src/sites/DojaCatInternalPilot/components/MessageBox/ConfirmOrderError';
import { DC_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/DojaCatInternalPilot/DojaCat';
import { getValueFromStageMap } from 'src/utils/roadieHelper';

const Home = () => {
  const gridItemRenderer = (merchItem: MerchItem, metadata: ItemMetadata) => {
    return (
      <MerchGridItem
        merchItem={merchItem}
        metadata={metadata}
        includeSessionIdInMetrics={DC_INCLUDE_SESSION_ID_IN_METRICS}
      />
    );
  };

  const gridErrorRenderer = () => {
    return (
      <MessageBox
        heading={UH_OH}
        text={GENERIC_ERROR_STRING}
        styles={confirmOrderErrorCss}
        testId="merchGridErrorBox"
      />
    );
  };

  return (
    <>
      <div className="homePageContainer" data-testid="homePage">
        <MerchGrid
          itemRenderer={gridItemRenderer}
          errorRenderer={gridErrorRenderer}
          engagementId={getValueFromStageMap(DOJA_CAT_ENGAGEMENTS)}
          site={SiteType.DOJACAT}
          includeSessionIdInMetrics={DC_INCLUDE_SESSION_ID_IN_METRICS}
          numColumnsSmallScreen={1}
          numColumnsMediumScreen={3}
          numColumnsLargeScreen={3}
          gridSpacing={3}
          styles={merchGridCss}
        />
      </div>
      <Footer />
    </>
  );
};

export default withPageMetrics(
  SiteType.DOJACAT,
  PageType.HOME,
  Home,
  DC_INCLUDE_SESSION_ID_IN_METRICS,
);
