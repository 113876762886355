export const imageBoxCss: Record<string, React.CSSProperties> = {
  imageBoxContainer: {
    height: '100%',
    width: '100%',
  },
  imageBoxColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageBoxSmallImage: {
    paddingBottom: '1rem',
    height: '100%',
    width: '100%',
  },
};
