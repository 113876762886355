import {
  FetchClientResponse,
  LogoutResponse,
  ValidateTokenRequest,
  ValidateTokenResponse,
} from '@amzn/mulberry-external-typescript-client';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SiteType } from 'src/frameworks/models/SiteType';
import { logger } from 'src/logger';
import { recordAPIError, recordAPIRequest } from 'src/metrics';
import { RoadieService } from 'src/services/roadie';
import type { RootState } from 'src/store/store';

export interface AuthState {
  expiryTime: number | null;
  validateTokenInProgress?: boolean;
  validateTokenComplete?: boolean;
  getClientIdInProgress?: boolean;
  getLogOutInProgress?: boolean;
  validateTokenError?: boolean;
  logOutError?: boolean;
  logOutSuccess?: boolean;
  getClientIdError?: boolean;
  clientId: string | undefined;
  csrfToken: string | undefined;
}

const initialState: AuthState = {
  expiryTime: null,
  clientId: undefined,
  csrfToken: undefined,
};

interface SetAccessTokenPayload {
  expiryTime: number | null;
  clientId: string | undefined;
  validateTokenComplete?: boolean; // for testing
  csrfToken: string | undefined;
}

export const getValidateToken = createAsyncThunk(
  'auth/validateToken',
  async ({ site, csrfToken }: { site: SiteType; csrfToken?: string }) => {
    recordAPIRequest(site, 'getValidateToken');
    try {
      const service = new RoadieService(site, csrfToken);
      const validateTokenRequest: ValidateTokenRequest = {};
      return (await service.validateToken(validateTokenRequest)) as ValidateTokenResponse;
    } catch (error) {
      recordAPIError(site, 'getValidateToken');
      logger.error(`Error getting validate token: ${error}`, {
        site: site,
      });
    }
  },
);

export const getClientId = createAsyncThunk(
  'auth/getClientId',
  async ({ site, csrfToken }: { site: SiteType; csrfToken?: string }) => {
    recordAPIRequest(site, 'getClientId');
    try {
      const service = new RoadieService(site, csrfToken);
      return (await service.fetchClientId()) as FetchClientResponse;
    } catch (error) {
      recordAPIError(site, 'getClientId');
      logger.error(`Error getting client Id: ${error}`, {
        site: site,
      });
    }
  },
);

export const logOut = createAsyncThunk('auth/logOut', async ({ site }: { site: SiteType }) => {
  recordAPIRequest(site, 'logOut');
  try {
    const service = new RoadieService(site);
    return (await service.logOut()) as LogoutResponse;
  } catch (error) {
    recordAPIError(site, 'logOut');
    logger.error(`Error logging out: ${error}`, {
      site: site,
    });
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<SetAccessTokenPayload>) => {
      state.expiryTime = action.payload.expiryTime;
      state.clientId = action.payload.clientId;
      state.validateTokenComplete = action.payload.validateTokenComplete;
      state.csrfToken = action.payload.csrfToken;
    },
    replaceAuthState: (state, action: PayloadAction<AuthState>) => {
      return action.payload;
    },
    updateExpiryTime: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        expiryTime: action.payload,
      };
    },
    updateClientId: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        clientId: action.payload,
      };
    },
    updateCsrfToken: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        csrfToken: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getValidateToken.pending, (state) => {
        return {
          ...state,
          validateTokenInProgress: true,
        };
      })
      .addCase(getValidateToken.fulfilled, (state, action) => {
        return {
          ...state,
          validateTokenInProgress: false,
          validateTokenComplete: action.payload?.valid,
          validateTokenError: undefined,
        };
      })
      .addCase(getValidateToken.rejected, (state) => {
        return {
          ...state,
          validateTokenInProgress: false,
          validateTokenComplete: false,
          validateTokenError: true,
        };
      })
      .addCase(getClientId.pending, (state) => {
        return {
          ...state,
          getClientIdInProgress: true,
        };
      })
      .addCase(getClientId.fulfilled, (state, action) => {
        return {
          ...state,
          getClientIdInProgress: false,
          clientId: action.payload?.clientId,
          getClientIdError: undefined,
        };
      })
      .addCase(getClientId.rejected, (state) => {
        return {
          ...state,
          getClientIdInProgress: false,
          getClientIdError: true,
        };
      })
      .addCase(logOut.pending, (state) => {
        return {
          ...state,
          getLogOutInProgress: true,
        };
      })
      .addCase(logOut.fulfilled, (state) => {
        return {
          ...state,
          logOutSuccess: true,
          validateTokenComplete: false,
          expiryTime: null,
          logOutError: undefined,
        };
      })
      .addCase(logOut.rejected, (state) => {
        return {
          ...state,
          logOutSuccess: false,
          logOutError: true,
        };
      });
  },
});

export const { setAuthState, replaceAuthState, updateExpiryTime, updateCsrfToken } =
  authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;
export const selectExpiryTime = (state: RootState) => state.auth?.expiryTime;
export const selectValidateTokenInProgress = (state: RootState) =>
  state.auth?.validateTokenInProgress;
export const selectValidateTokenComplete = (state: RootState) => state.auth?.validateTokenComplete;
export const selectValidateTokenError = (state: RootState) => state.auth?.validateTokenError;
export const selectGetClientIdError = (state: RootState) => state.auth?.getClientIdError;
export const selectLogOutError = (state: RootState) => state.auth?.logOutError;
export const selectLogOutSuccess = (state: RootState) => state.auth?.logOutSuccess;
export const selectClientId = (state: RootState) => state.auth?.clientId;
export const selectCsrfToken = (state: RootState) => state.auth?.csrfToken;

export default authSlice.reducer;
