import { DOJA_CAT_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import { OrderPage, OrderPageStyles } from 'src/pages/OrderPage';
import withPageMetrics from 'src/shared/withPageMetrics';
import { withRouter } from 'src/shared/withRouter';
import { buttonCss } from 'src/sites/DojaCatInternalPilot/components/Button/ButtonCss';
import { confirmOrderErrorCss } from 'src/sites/DojaCatInternalPilot/components/MessageBox/ConfirmOrderError';
import { orderItemCss } from 'src/sites/DojaCatInternalPilot/components/Order/OrderItemCss';
import { orderListCss } from 'src/sites/DojaCatInternalPilot/components/Order/OrderListCss';
import { orderSummaryCSS } from 'src/sites/DojaCatInternalPilot/components/Order/OrderSummaryCss';
import { DC_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/DojaCatInternalPilot/DojaCat';

const $orderPageContainer: React.CSSProperties = {
  paddingLeft: '5rem',
  paddingRight: '5rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  maxWidth: '1400px',
  //`@media (min-width: 900px)` { flex-direction: row; } // FIXME research media query support
};

const $style: OrderPageStyles = {
  orderPageContainer: $orderPageContainer,
  orderItemStyle: orderItemCss,
  orderItemListStyle: orderListCss,
  orderSummaryStyle: orderSummaryCSS,
  errorMessageBoxStyle: confirmOrderErrorCss,
  errorButtonStyle: buttonCss,
  paymentAndAddressNote: { alignSelf: 'flex-start' },
  privacyNoticeAndConditionOfUseNote: { alignSelf: 'flex-end' },
  checkoutButtonStyles: {
    confirmButtonStyle: {
      backgroundColor: 'var( --quaternary-color)',
      borderRadius: 0,
      border: '1px solid #fff',
    },
  },
};

const DojaCatOrderPage = () => {
  return (
    <OrderPage
      siteType={SiteType.DOJACAT}
      sitePrefix={DOJA_CAT_PREFIX}
      style={$style}
      includeSessionIdInMetrics={DC_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};
export default withPageMetrics(
  SiteType.DOJACAT,
  PageType.ORDER_PAGE,
  withRouter(DojaCatOrderPage),
  DC_INCLUDE_SESSION_ID_IN_METRICS,
);
