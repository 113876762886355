export const dcLoadingSpinnerContainer: React.CSSProperties = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  margin: '0',
  boxSizing: 'border-box',
  position: 'absolute',
  top: '-72px',
};

export const dcProductDetailPageInfo: React.CSSProperties = {
  paddingTop: '4rem',
  width: '100%',
  position: 'sticky',
  maxWidth: '31.25rem',
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const dcVariationLabel: React.CSSProperties = {
  fontFamily: 'var(--font-family)',
  color: 'var(--secondary-color)',
  fontSize: '0.75rem',
  marginBottom: '0.5rem',
};
