import { ItemMetadata, MerchItem } from '@amzn/mulberry-external-typescript-client';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteType } from 'src/frameworks/models/SiteType';
import { recordClick } from 'src/metrics';
import { ActionType, ElementType, PageType } from 'src/metricsTypes/metricsTypes';
import { selectSessionId } from 'src/store/identifierSlice';

interface MerchGridItemProps {
  includeSessionIdInMetrics: boolean;
  merchItem: MerchItem;
  metadata: ItemMetadata;
}

const MerchGridItem = (props: MerchGridItemProps) => {
  const navigate = useNavigate();
  const image = props.metadata.images?.[0];
  const sessionId = useSelector(selectSessionId);

  const goProductDetail = (): void => {
    recordClick(
      SiteType.DOJACAT,
      ActionType.GO_PRODUCT_DETAIL,
      props.includeSessionIdInMetrics ? sessionId : undefined,
      PageType.HOME,
      ElementType.MERCH_GRID_ITEM,
      props.merchItem.itemAsin,
    );
    navigate(`/product/${props.merchItem.itemAsin}`);
  };

  return (
    <a
      onClick={goProductDetail}
      className="merchGridItemContainer"
      data-testid="merchGridItemContainer"
    >
      <img
        className="merchGridItemImage"
        data-testid="merchGridItemImage"
        src={image?.lowRes?.url ?? ''}
        alt={image?.altText ?? ''}
      />
      <div className="merchGridItemTextContainer" data-testid="merchGridItemTextContainer">
        <div className="merchGridItemName" data-testid="merchGridItemName">
          {props.metadata.name}
        </div>
        <div className="merchGridItemPrice" data-testid="merchGridItemPrice">
          {props.metadata.price}
        </div>
      </div>
    </a>
  );
};

export default MerchGridItem;
