export const checkoutButtonCss: Record<string, React.CSSProperties> = {
  buttonContainer: {
    height: '2.375rem',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    minWidth: '14.0625rem',
    borderWidth: '.0625rem',
    borderColor: 'var(--secondary-color)',
    width: '100%',
    padding: '1rem',
    display: 'inline-flex',
    backgroundColor: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    fontSize: '.75rem',
    alignItems: 'center',
    left: 0,
    bottom: 0,
  },
};
