import {
  ConfirmOrderRequest,
  ConfirmOrderResponse,
  PreviewOrderRequest,
  PreviewOrderResponse,
  PurchaseItem,
} from '@amzn/mulberry-external-typescript-client';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosHeaders, AxiosRequestConfig } from 'axios';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { GENERIC_ERROR_CODE } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { logger } from 'src/logger';
import { recordAPIError, recordAPIRequest } from 'src/metrics';
import { RoadieService } from 'src/services/roadie';
import { isSamePurchaseItem } from 'src/utils/utils';

interface ApiErrorResponse {
  config: AxiosRequestConfig;
  request: XMLHttpRequest;
  response?: {
    data: {
      errorCodes: string[];
      message: string;
    };
    status: number;
    statusText: string;
    headers: AxiosHeaders;
    config: AxiosRequestConfig;
  };
  message: string;
  name: string;
  code?: string;
  stack?: string;
}
export interface Cart {
  items: PurchaseItem[];
  quantity: number;
  subtotal: number;
}

export interface CartState {
  cart: Cart | null;
  previewOrderInProgress: boolean;
  previewOrderComplete?: string; // purchase ID
  confirmOrderInProgress: boolean;
  confirmOrderComplete?: string; //yourOrdersPageUrl
  order?: PreviewOrderResponse;
  error?: string[];
}

export const initialCartState: CartState = {
  cart: { items: [], quantity: 0, subtotal: 0 },
  previewOrderInProgress: false,
  confirmOrderInProgress: false,
};

export const getPreviewOrder = createAsyncThunk(
  'cart/previewOrder',
  async (
    {
      site,
      marketplaceId,
      locale,
      purchaseItems,
      csrfToken,
    }: {
      site: SiteType;
      marketplaceId: string;
      locale: string;
      purchaseItems: PurchaseItem[];
      csrfToken?: string;
    },
    { rejectWithValue },
  ) => {
    recordAPIRequest(site, 'getPreviewOrder');
    try {
      const service = new RoadieService(site, csrfToken);
      const previewOrderRequest: PreviewOrderRequest = {
        marketplaceId: marketplaceId,
        locale: locale,
        items: purchaseItems,
      };
      return await service.previewOrder(previewOrderRequest);
    } catch (error) {
      const apiError: ApiErrorResponse = error as ApiErrorResponse;
      const errorCodes: string[] = apiError.response?.data.errorCodes ?? [GENERIC_ERROR_CODE];
      recordAPIError(site, 'getPreviewOrder');
      logger.error(`Error getting order preview with error codes: ${errorCodes}`, {
        site: site,
      });
      return rejectWithValue(errorCodes);
    }
  },
);

export const getConfirmOrder = createAsyncThunk(
  'cart/confirmOrder',
  async (
    {
      site,
      purchaseId,
      csrfToken,
    }: {
      site: SiteType;
      purchaseId: string;
      csrfToken?: string;
    },
    { rejectWithValue },
  ) => {
    recordAPIRequest(site, 'getConfirmOrder');
    try {
      const service = new RoadieService(site, csrfToken);
      const confirmOrderRequest: ConfirmOrderRequest = {
        purchaseId: purchaseId,
      };
      return await service.confirmOrder(confirmOrderRequest);
    } catch (error) {
      const apiError: ApiErrorResponse = error as ApiErrorResponse;
      const errorCodes: string[] = apiError.response?.data.errorCodes ?? [GENERIC_ERROR_CODE];
      recordAPIError(site, 'getConfirmOrder');
      logger.error(`Error getting confirmed order: ${errorCodes}`, {
        site: site,
      });
      return rejectWithValue(errorCodes);
    }
  },
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    replaceCartState: (state, action: PayloadAction<CartState>) => {
      return action.payload;
    },
    setCart: (state, action: PayloadAction<{ cart: Cart }>) => {
      const { cart } = action.payload;
      state.cart = {
        ...state.cart,
        items: (cart.items as PurchaseItem[]).filter((item) => (item.quantity ?? 0) > 0),
        quantity: cart.quantity,
        subtotal: cart.subtotal,
      };
    },
    addToCart: (
      state,
      action: PayloadAction<{ purchaseItem: PurchaseItem; quantity: number; subtotal: number }>,
    ) => {
      const { purchaseItem, quantity, subtotal } = action.payload;

      if (!state.cart) {
        state.cart = { items: [], quantity: 0, subtotal: 0 };
      }

      const oldQuantity = state.cart.quantity;
      const oldSubtotal = state.cart.subtotal;
      const cartCopy = { ...state.cart };
      const existingItemIndex = !isEmpty(state.cart.items)
        ? state.cart.items.findIndex(
            (cartItem) =>
              cartItem.asin === purchaseItem.asin &&
              isEqual(cartItem.customId, purchaseItem.customId) &&
              isEqual(cartItem.customizationToken, purchaseItem.customizationToken) &&
              cartItem.offerId === purchaseItem.offerId &&
              cartItem.merchantId === purchaseItem.merchantId,
          )
        : -1;
      if (existingItemIndex !== -1) {
        const existingItem = cartCopy.items[existingItemIndex];
        const newItemQuantity = (existingItem.quantity || 0) + quantity;
        const newItem = { ...existingItem, quantity: newItemQuantity };
        cartCopy.items[existingItemIndex] = newItem;
      } else {
        // Item is not in the cart, add it
        state.cart.items.push({ ...purchaseItem, quantity: quantity });
      }

      const roundedSubtotal = parseFloat((oldSubtotal + subtotal).toFixed(2));
      state.cart = {
        ...state.cart,
        items: cartCopy.items,
        quantity: oldQuantity + quantity,
        subtotal: roundedSubtotal,
      };
    },
    removeFromCart: (
      state,
      action: PayloadAction<{ purchaseItem: PurchaseItem; quantity: number; subtotal: number }>,
    ) => {
      const { purchaseItem, quantity, subtotal } = action.payload;
      if (!state.cart) {
        state.cart = { items: [], quantity: 0, subtotal: 0 };
        return;
      }
      const oldQuantity = state.cart.quantity;
      const oldSubtotal = state.cart.subtotal;
      const cartCopy = { ...state.cart };

      const existingItem = state.cart?.items.find((item: PurchaseItem) =>
        isSamePurchaseItem(item, purchaseItem),
      );

      if (existingItem) {
        const updatedItemsAfterRemove =
          cartCopy.items
            .map((item: PurchaseItem) => {
              if (isSamePurchaseItem(item, purchaseItem)) {
                return {
                  ...item,
                  quantity: Math.max(0, (item.quantity || 0) - quantity),
                };
              }
              return item;
            })
            .filter((item: PurchaseItem) => (item.quantity || 0) > 0) ?? [];

        const roundedSubtotal = parseFloat(Math.max(0, (oldSubtotal || 0) - subtotal).toFixed(2));
        state.cart = {
          ...state.cart,
          items: updatedItemsAfterRemove,
          quantity: Math.max(0, (oldQuantity || 0) - quantity),
          subtotal: roundedSubtotal,
        };
      }
    },
    resetPreviewOrderState: (state) => {
      return {
        ...state,
        previewOrderInProgress: false,
        previewOrderComplete: undefined,
        order: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreviewOrder.pending, (state) => {
        return {
          ...state,
          previewOrderInProgress: true,
        };
      })
      .addCase(getPreviewOrder.fulfilled, (state, action) => {
        return {
          ...state,
          order: action.payload,
          previewOrderInProgress: false,
          previewOrderComplete: action.payload.purchaseId,
          error: undefined,
        };
      })
      .addCase(getPreviewOrder.rejected, (state, action) => {
        return {
          ...state,
          previewOrderInProgress: false,
          error: action.payload as string[],
        };
      })
      .addCase(getConfirmOrder.pending, (state) => {
        return {
          ...state,
          confirmOrderInProgress: true,
        };
      })
      .addCase(getConfirmOrder.fulfilled, (state, action) => {
        const payload = action.payload as ConfirmOrderResponse;
        return {
          ...state,
          confirmOrderInProgress: false,
          confirmOrderComplete: payload.yourOrdersPageUrl,
          error: undefined,
        };
      })
      .addCase(getConfirmOrder.rejected, (state, action) => {
        return {
          ...state,
          confirmOrderInProgress: false,
          error: action.payload as string[],
        };
      });
  },
  selectors: {
    selectCart: (state) => state.cart,
    selectPreviewOrderInProgress: (state) => state.previewOrderInProgress,
    selectPreviewOrderComplete: (state) => state.previewOrderComplete,
    selectConfirmOrderInProgress: (state) => state.confirmOrderInProgress,
    selectConfirmOrderComplete: (state) => state.confirmOrderComplete,
    selectOrder: (state) => state.order,
    selectOrderError: (state) => state.error,
  },
});

export const selectCart = cartSlice.selectors.selectCart;
export const selectPreviewOrderInProgress = cartSlice.selectors.selectPreviewOrderInProgress;
export const selectPreviewOrderComplete = cartSlice.selectors.selectPreviewOrderComplete;
export const selectConfirmOrderComplete = cartSlice.selectors.selectConfirmOrderComplete;
export const selectOrder = cartSlice.selectors.selectOrder;
export const selectOrderError = cartSlice.selectors.selectOrderError;

export const { replaceCartState, resetPreviewOrderState, addToCart, removeFromCart, setCart } =
  cartSlice.actions;

export default cartSlice.reducer;
