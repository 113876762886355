import 'src/sites/DojaCatInternalPilot/components/CartDrawer/CartDrawer.scss';

import React from 'react';
import CartDrawer from 'src/components/Cart/CartDrawer';
import { DOJA_CAT_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';

import { DC_INCLUDE_SESSION_ID_IN_METRICS } from '../../DojaCat';
import { cartListCss } from '../Cart/CartCss';
import { DojaCatCartItem } from '../Cart/DojaCatCartItem';
import { checkoutButtonCss } from './CheckoutButtonCss';

export interface DojaCartDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const DojaCatCartDrawer = (props: DojaCartDrawerProps) => {
  return (
    <CartDrawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      sitePrefix={DOJA_CAT_PREFIX}
      siteType={SiteType.DOJACAT}
      includeSessionIdInMetrics={DC_INCLUDE_SESSION_ID_IN_METRICS}
      styles={{
        checkoutButton: checkoutButtonCss,
        cartListStyle: cartListCss,
      }}
      cartItemRenderer={DojaCatCartItem}
    />
  );
};

export default DojaCatCartDrawer;
