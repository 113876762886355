import { STAGE_MAP } from 'src/services/roadie';
export const BATCH_SIZE = 50;
export const MAX_RETRIES = 3;
export const MAX_ASINS_IN_CART = 10;

export const EXAMPLE_ENGAGEMENTS: STAGE_MAP = {
  Prod: '340682fb-1399-489b-b5fd-6f0f6b72411e',
  Gamma: '340682fb-1399-489b-b5fd-6f0f6b72411e',
  Beta: '340682fb-1399-489b-b5fd-6f0f6b72411e',
};
export const DOJA_CAT_ENGAGEMENTS: STAGE_MAP = {
  Prod: '04dba376-0557-4815-b796-10646cd4ccee',
  Gamma: '04dba376-0557-4815-b796-10646cd4ccee',
  Beta: '04dba376-0557-4815-b796-10646cd4ccee',
};
export const TORMENTED_VALLEY_ENGAGEMENTS: STAGE_MAP = {
  Prod: '82589454-4dac-464e-81a1-12eb008424b5',
  Gamma: '82589454-4dac-464e-81a1-12eb008424b5',
  Beta: '8c876795-cfbf-4c64-8b0e-f37c3e193740',
};
export const PLUMERIA_ENGAGEMENTS: STAGE_MAP = {
  Prod: 'd92ea096-289b-45d6-96b8-2a52e7ffb176',
  Gamma: 'fee2b86e-afea-46a7-9809-4db832f47c1d',
  Beta: '93ad51a4-09fa-4a5f-8ec4-f1305bf2b618',
};

export const TORMENTED_VALLEY_PROD_ENDPOINT = 'https://api.shop.thetormentedvalley.com/';

export const PLUMERIA_PROD_ENDPOINT = 'https://api.store.pesopluma.com/';

export const DEFAULT_PROD_ENDPOINT = 'https://music-merch.com/';
