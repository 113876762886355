export const productDetailBoxCss: Record<string, React.CSSProperties> = {
  productDetailBoxContainer: {
    color: 'var( --secondary-color )',
    fontFamily: 'var( --font-family )',
    fontWeight: 'var( --font-weight )',
    fontSize: '0.75rem',
    listStyle: 'none',
    paddingLeft: '0',
  },
};
