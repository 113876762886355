import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SwipeableDrawer } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteProps } from 'src/components/App/App';
import Button from 'src/components/Button/Button';
import CheckoutButton, { CheckoutButtonType } from 'src/components/Button/CheckoutButton';
import LinkAccountButton from 'src/components/Button/LinkAccountButton';
import { CartItemProps } from 'src/components/Cart/CartItem';
import CartList from 'src/components/Cart/CartList';
import { getRedirectUrl } from 'src/constants/lwa';
import { MAX_ASINS_IN_CART } from 'src/constants/roadie';
import {
  CONTINUE_SHOPPING_STRING,
  EMPTY_CART_STRING,
  SUBTOTAL_TEXT,
  YOUR_CART_STRING,
} from 'src/constants/strings';
import { recordClick } from 'src/metrics';
import { ActionType } from 'src/metricsTypes/metricsTypes';
import { CartPageStyles } from 'src/pages/CartPage';
import {
  getValidateToken,
  selectAuthState,
  selectClientId,
  selectCsrfToken,
  selectExpiryTime,
  selectValidateTokenComplete,
} from 'src/store/authSlice';
import { selectCart } from 'src/store/cartSlice';
import { selectCatalog } from 'src/store/catalogSlice';
import { selectSessionId } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';
import {
  getFooterText,
  getValidCart,
  handleAuth,
  hasUnavailableItems,
  hydrateCatalogFromCart,
  reloadWindowOnStorageChange,
  ValidCartItem,
} from 'src/utils/utils';

export interface CartDrawerProps extends SiteProps {
  styles?: CartPageStyles;
  cartItemRenderer: (props: CartItemProps) => ReactElement;
  isOpen: boolean;
  onClose: () => void;
}

const CartDrawer = (props: CartDrawerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const catalog = useSelector(selectCatalog);
  const [validSubtotal, setValidSubtotal] = useState('0.00');
  const [validQuantity, setValidQuantity] = useState(0);
  const [validHydratedCartItems, setValidHydratedCartItems] = useState<ValidCartItem[]>([]);
  const cartIsEmpty = validQuantity === 0;
  const accessTokenExists = useSelector(selectValidateTokenComplete);
  const expiryTime = useSelector(selectExpiryTime);
  const clientId = useSelector(selectClientId);
  const authState = useSelector(selectAuthState);
  const [hasUnavailableItem, setHasUnavailableItem] = useState(false);
  const maxItemsExceeded: boolean = validHydratedCartItems.length > MAX_ASINS_IN_CART;
  const isLoggedIn: boolean = !!accessTokenExists && !!expiryTime && Date.now() < expiryTime;
  const csrfToken = useSelector(selectCsrfToken);
  const sessionId = useSelector(selectSessionId);

  useEffect(() => {
    reloadWindowOnStorageChange(dispatch, authState);
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getValidateToken({
        site: props.siteType,
        csrfToken: csrfToken,
      }),
    );
  }, []);

  // Hydrate catalog
  useEffect(() => {
    if (cart) {
      hydrateCatalogFromCart(cart, dispatch, props.siteType, csrfToken);
    }
  }, [expiryTime, accessTokenExists, csrfToken]);

  // Use subtotal of ONLY valid cart items i.e. cart items that can be hydrated by the catalog
  useEffect(() => {
    if (catalog && cart) {
      const cartItems = cart?.items ?? [];
      const validCart = getValidCart(cartItems, catalog);
      const validItems = validCart.cartItems;
      setHasUnavailableItem(hasUnavailableItems(validItems));
      setValidHydratedCartItems(validItems);
      setValidSubtotal(validCart.subtotal);
      setValidQuantity(validCart.quantity);
    }
  }, [cart, catalog]);

  const goCheckout = (): void => {
    recordClick(
      props.siteType,
      ActionType.GO_CHECKOUT,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.onClose();
    navigate('/checkout');
  };

  // If cart is empty 'checkout' button navigates to home page matching store.dojacat.com
  const goHome = (): void => {
    props.onClose();
    recordClick(
      props.siteType,
      ActionType.GO_HOME,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/`);
  };

  return (
    <SwipeableDrawer
      onOpen={props.onClose}
      onClose={props.onClose}
      anchor="right"
      open={props.isOpen}
      data-testid="cartDrawer"
    >
      <div className="cartContent">
        <div className="cartHeader" data-testid="cartHeader">
          <div className="cartHeaderText" data-testid="cartHeaderText">
            {YOUR_CART_STRING}
          </div>

          <IconButton
            size="small"
            className="closeCartButton"
            data-testid="closeCartButton"
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="cartList" data-testid="cartList">
          <CartList
            sitePrefix={props.sitePrefix}
            siteType={props.siteType}
            includeSessionIdInMetrics={props.includeSessionIdInMetrics}
            hideEmptyCartButton={true}
            emptyCartMessage={EMPTY_CART_STRING}
            styles={props.styles?.cartListStyle}
            itemRenderer={props.cartItemRenderer}
            validCartItems={validHydratedCartItems}
          />
        </div>
        <div className="cartFooter" data-testid="cartFooter">
          <div className="cartSubtitleContainer" data-testid="cartSubtitleContainer">
            <div className="cartSubtotalContainer" data-testid="cartSubtotalContainer">
              <div className="cartSubtotalLabel" data-testid="cartSubtotalLabel">
                {SUBTOTAL_TEXT}
              </div>
              <div className="cartSubtotal" data-testid="cartSubtotal">
                ${validSubtotal}
              </div>
            </div>
            <div className="cartFooterText" data-testid="cartFooterText">
              {getFooterText(isLoggedIn, maxItemsExceeded, hasUnavailableItem)}
            </div>
          </div>
          {cartIsEmpty && (
            <Button
              text={CONTINUE_SHOPPING_STRING}
              onClick={goHome}
              testId="checkoutButton"
              classNamePrefix={props.sitePrefix}
              styles={props.styles?.checkoutButton}
            />
          )}
          {!cartIsEmpty &&
            (isLoggedIn ? (
              <CheckoutButton
                // Disable checkout button when at least one items are unavailable or max limit has been exceeded
                disabled={hasUnavailableItem || maxItemsExceeded}
                type={CheckoutButtonType.CHECKOUT}
                onClick={goCheckout}
                testId="checkoutButton"
                classNamePrefix={props.sitePrefix}
              />
            ) : (
              <LinkAccountButton
                onClick={() => handleAuth(clientId ?? '', getRedirectUrl(props.siteType))}
                testId="linkAccountButton"
                classNamePrefix={props.sitePrefix}
              />
            ))}
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default CartDrawer;
