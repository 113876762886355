export const buttonGroupCss: Record<string, React.CSSProperties> = {
  pressedButton: {
    backgroundColor: 'var(--secondary-color)',
    marginBottom: '1rem',
    padding: '.5rem',
    color: 'var(--primary-color)',
    borderColor: 'var(--tertiary-color)',
    borderWidth: '1px',
  },
  defaultButton: {
    backgroundColor: '#101011',
    color: 'var(--secondary-color)',
    marginBottom: '1rem',
    padding: '.5rem',
    borderColor: 'var(--secondary-color)',
    borderWidth: '1px',
  },
  buttonGroupContainer: {
    gap: '.75rem',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
};
