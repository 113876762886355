import 'src/sites/DojaCatInternalPilot/components/NavBar/NavBar.css';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CART_BUTTON_STRING,
  CLOSE_BUTTON_STRING,
  HOME_STRING,
  MENU_BUTTON_STRING,
  MULBERRY_STRING,
  SHOP_ALL_STRING,
} from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { recordClick } from 'src/metrics';
import { ActionType } from 'src/metricsTypes/metricsTypes';
import DojaCatCartDrawer from 'src/sites/DojaCatInternalPilot/components/CartDrawer/CartDrawer';
import { selectCart } from 'src/store/cartSlice';
import { selectSessionId } from 'src/store/identifierSlice';

interface NavBarProps {
  includeSessionIdInMetrics: boolean;
  isCartDrawerOpen: boolean;
  setIsCartDrawerOpen: (open: boolean) => void;
  isNavMenuOpen: boolean;
  setIsNavMenuOpen: (open: boolean) => void;
}

const NavBar = (props: NavBarProps) => {
  const navigate = useNavigate();
  const handleToggleCartDrawer = () => {
    props.setIsCartDrawerOpen(!props.isCartDrawerOpen);
  };
  const cart = useSelector(selectCart);
  const sessionId = useSelector(selectSessionId);

  const goHome = (): void => {
    recordClick(
      SiteType.DOJACAT,
      ActionType.GO_HOME,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/`);
    props.setIsNavMenuOpen(false);
  };
  const goShopAll = (): void => {
    recordClick(
      SiteType.DOJACAT,
      ActionType.GO_SHOP_ALL,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/`);
    props.setIsNavMenuOpen(false);
  };

  const onClickMenu = () => {
    recordClick(
      SiteType.DOJACAT,
      ActionType.SHOW_NAV_MENU,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.setIsNavMenuOpen(true);
  };
  const onClickCloseButton = () => {
    recordClick(
      SiteType.DOJACAT,
      ActionType.CLOSE_NAV_MENU,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.setIsNavMenuOpen(false);
  };

  return (
    <header className="navBar" data-testid="dojaCatNavBar">
      {props.isNavMenuOpen && (
        <div className="navMenuPopOverContainer" data-testid="navMenuPopOverContainer">
          <div className="navMenuCloseButtonContainer" data-testid="navMenuCloseButtonContainer">
            <button
              className="navMenuCloseButton"
              data-testid="navMenuCloseButton"
              onClick={onClickCloseButton}
            >
              <title>{CLOSE_BUTTON_STRING}</title>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10.18 10.19"
                className="navMenuClose"
                data-testid="navMenuClose"
              >
                <path
                  className="navMenuClosePath"
                  data-testid="navMenuClosePath"
                  fill="white"
                  d="m6.29,5.09l3.64-3.64c.33-.33.33-.87,0-1.2-.33-.33-.87-.33-1.2,0l-3.64,3.64L1.45.25C1.12-.08.58-.08.25.25-.08.58-.08,1.12.25,1.45l3.64,3.64L.25,8.73c-.33.33-.33.87,0,1.2.17.17.38.25.6.25s.44-.08.6-.25l3.64-3.64,3.64,3.64c.17.17.38.25.6.25s.43-.08.6-.25c.33-.33.33-.87,0-1.2l-3.64-3.64Z"
                ></path>
              </svg>
            </button>
          </div>
          <ol className="navMenuList" data-testid="navMenuList">
            <li className="navMenuListItem" data-testid="navMenuListItem">
              <a onClick={goHome}>{HOME_STRING}</a>
            </li>
            <li className="navMenuListItem" data-testid="navMenuListItem">
              <a onClick={goShopAll}>{SHOP_ALL_STRING}</a>
            </li>
          </ol>
        </div>
      )}
      <button className="iconMenuButton" data-testid="iconMenuButton" onClick={onClickMenu}>
        <svg
          className="iconMenu"
          xmlns="http://www.w3.org/2000/svg"
          data-testid="iconMenu"
          viewBox="0 0 20 12"
        >
          <title>{MENU_BUTTON_STRING}</title>

          <path
            data-testid="iconMenuPath"
            fill="white"
            d="M19,12H1c-.55,0-1-.45-1-1s.45-1,1-1h18c.55,0,1,.45,1,1s-.45,1-1,1Zm0-5H1c-.55,0-1-.45-1-1s.45-1,1-1h18c.55,0,1,.45,1,1s-.45,1-1,1Zm0-5H1c-.55,0-1-.45-1-1s.45-1,1-1h18c.55,0,1,.45,1,1s-.45,1-1,1Z"
          ></path>
        </svg>
      </button>
      <a className="navBarLogo" data-testid="navBarLogo" onClick={goHome}>
        {MULBERRY_STRING}
      </a>
      <DojaCatCartDrawer isOpen={props.isCartDrawerOpen} onClose={handleToggleCartDrawer} />
      <button
        className="cartIconButton"
        data-testid="cartIconButton"
        onClick={handleToggleCartDrawer}
      >
        <svg
          className="cartIcon"
          xmlns="http://www.w3.org/2000/svg"
          data-testid="cartIcon"
          viewBox="0 0 19.73 20"
        >
          <title>{CART_BUTTON_STRING}</title>
          <path
            data-testid="cartIconPath"
            fill="white"
            d="m16,20c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm-10,0c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm9.42-5H5c-.48,0-.9-.34-.98-.82L1.86,2.49c-.04-.23-.09-.46-.11-.53,0,.04-.25.04-.48.04h-.27c-.55,0-1-.45-1-1S.45,0,1,0h.27c.56,0,.92,0,1.31.17.34.15.63.39.84.7.24.35.3.71.41,1.26l.16.87h13c.81,0,1.31,0,1.78.28.42.25.73.64.88,1.1.17.52.06,1-.12,1.8l-1.57,6.8c-.12.53-.2.86-.44,1.19-.21.29-.5.52-.83.66-.38.16-.73.16-1.27.16Zm-9.59-2h9.59c.22,0,.45,0,.52-.01-.04-.01.02-.23.06-.44l1.57-6.81c.06-.28.13-.57.15-.72-.15-.02-.45-.02-.74-.02H4.36l1.48,8Z"
          ></path>
        </svg>
        <div className="cartQuantity" data-testid="cartQuantity">
          {cart?.quantity ?? 0}
        </div>
      </button>
    </header>
  );
};

export default NavBar;
