export const primaryInfoBoxCss: Record<string, React.CSSProperties> = {
  primaryInfoBoxContainer: {
    color: 'var( --secondary-color )',
    marginBottom: '.5rem',
    width: '100%',
  },
  primaryInfoBoxHeader: {
    fontFamily: 'var( --font-family )',
    fontWeight: 'var( --font-weight )',
    fontSize: '1rem',
    marginTop: '0px',
    marginBottom: '1rem',
  },
  primaryInfoBoxText: {
    fontFamily: 'var( --font-family )',
    fontWeight: 'var( --font-weight )',
    fontSize: '.75rem',
  },
  primaryInfoBoxPrice: { marginBottom: '.5rem' },
  primaryInfoBoxAvailability: { marginBottom: '.5rem' },
  primaryInfoBoxReturn: {},
};
