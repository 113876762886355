export const orderItemCss: Record<string, React.CSSProperties> = {
  orderItemContainer: {
    border: '1px solid var(--secondary-color)',
    background: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    width: '100%',
    padding: '1rem',
    display: 'flex',
    boxSizing: 'border-box',
    gap: '1rem',
    fontFamily: 'var(--font-family)',
    minWidth: '25rem',
  },
  orderInfoContainer: {
    width: '100%',
  },
  orderItemImage: {
    width: '10rem',
    height: '10rem',
  },
  arrivalDateString: {
    marginBottom: '.75rem',
    fontSize: '.8rem',
  },
  titleString: {
    fontSize: '1rem',
  },
  priceQuantityContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '.75rem',
    marginTop: 'auto',
  },
  subtotalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    height: '100%',
    boxSizing: 'border-box',
    minWidth: '4rem',
    textAlign: 'center',
  },
  subtotalString: {
    marginTop: '.5rem',
  },
  subtotalLabelString: {
    marginTop: 'auto',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    height: '100%',
    boxSizing: 'border-box',
    minWidth: '4rem',
    textAlign: 'center',
  },
  priceString: {
    marginTop: '.5rem',
  },
  priceLabelString: {
    marginTop: 'auto',
  },
  quantityContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
    minWidth: '4rem',
    textAlign: 'center',
  },
  quantityLabelString: {
    marginTop: 'auto',
  },
  quantityString: {
    marginTop: '.5rem',
  },
  orderInfoOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  variationOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  variationContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.9rem',
    fontFamily: 'var(--font-family)',
    marginRight: '0.5rem',
  },
  variationKeyContainer: { marginRight: '0.15rem' },
  cartPriceContainer: {
    color: 'rgb(96, 96, 96)',
    marginBottom: '0.5rem',
    fontSize: '1.25rem',
    maxWidth: 300,
    width: '100%',
  },
};
