export const cartListCss: Record<string, React.CSSProperties> = {
  cartListContainer: {
    listStyleType: 'none',
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    paddingInlineStart: '0px',
    paddingInlineEnd: '1rem',
    minHeight: '200px',
    width: '100%',
    left: '0',
  },
  cartListItem: {
    listStyleType: 'none',
  },
  emptyCartListMessage: {
    color: 'var(--secondary-color)',
    fontSize: '.75rem',
  },
  emptyCartListContainer: {
    padding: '1rem',
    display: 'inline-flex',
    flexDirection: 'column',
    paddingInlineStart: '0px',
    paddingInlineEnd: '0px',
    minHeight: '200px',
    width: '100%',
    left: '0',
  },
  buttonContainer: {
    fontWeight: 'bold',
    padding: '10px 20px',
    background: 'linear-gradient(to bottom, #333, #555)',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
  },
};
