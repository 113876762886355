import CartItem, { CartItemProps, CartItemStyle } from 'src/components/Cart/CartItem';

const $cartItemStyle: CartItemStyle = {
  cartItemContainer: {
    background: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: '7.8125rem',
    gap: '1rem',
    marginRight: 'calc(100% - 100vw)',
  },
  cartItemInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: 125,
    height: '100%',
    justifyContent: 'space-between',
  },
  cartItemImage: {
    width: 125,
    height: 125,
  },
  cartTitleString: {
    paddingRight: '0.5rem',
    display: '-webkit-box',
    lineClamp: '2',
    WebkitLineClamp: '2',
    MozBoxOrient: 'vertical',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.8rem',
  },
  cartItemInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cartItemFirstRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '0.25rem',
  },
  cartItemSecondColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'stretch',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cartAvailabilityString: {
    width: '100%',
    marginBottom: '0.3rem',
    fontSize: '0.6rem',
  },
  removeItemButton: {
    backgroundColor: 'var(--quaternary-color)',
    boxSizing: 'border-box',
    border: '0 solid',
    margin: 0,
    padding: '0 !important',
    width: '0.75rem',
    minWidth: '0.75rem',
    height: '0.75rem',
  },
  removeItemButtonIcon: {
    color: 'white',
  },
  totalPriceContainer: {
    display: 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  totalPriceString: {
    fontSize: '1.25rem',
  },
  cartPriceString: {
    fontSize: '0.7rem',
  },
  variationOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  variationContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.6rem',
    fontFamily: 'var(--font-family)',
    flexGrow: '1',
  },
  variationKeyContainer: {
    marginRight: '0.25rem',
  },
  cartItemQuantitySelectorStyle: {
    quantitySelectorContainer: {
      display: 'flex',
      flexDirection: 'row',
      color: 'white',
      fontSize: '0.75rem',
      border: '1px solid white',
      width: '5rem',
      height: '1.5rem',
    },
    quantitySelectorButton: {
      padding: '0 0.5rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.8rem',
      verticalAlign: 'top',
      textAlign: 'center',
      lineHeight: '1.5rem',
      WebkitUserSelect: 'none',
      userSelect: 'none',
      MozUserSelect: 'none',
      width: '1rem',
    },
    quantitySelectorInput: {
      width: '2rem',
      height: '1.5rem',
      padding: '6px 12px',
      textAlign: 'center',
      fontFamily: 'var(--font-family)',
      border: 'none',
      boxSizing: 'border-box',
      color: 'white',
    },
    deliveryMessageOuterContainer: {
      display: 'none',
    },
    deliveryMessageContainer: {
      display: 'none',
    },
  },
};

export const DojaCatCartItem = (props: CartItemProps) => {
  return <CartItem {...props} styles={$cartItemStyle} />;
};
