export const orderSummaryCSS: Record<string, React.CSSProperties> = {
  orderSummaryContainer: {
    color: 'var(--secondary-color)',
    border: '1px solid var(--secondary-color)',
    padding: '1rem',
    marginBottom: '1rem',
    marginTop: '3.75rem',
    boxSizing: 'border-box',
    fontFamily: 'var(--font-family)',
    width: '100%',
  },
  orderSummaryHeader: {
    margin: '0',
    marginBottom: '1rem',
    fontFamily: 'var(--font-family)',
    fontWeight: 'normal',
    fontSize: '1.5rem',
    textTransform: 'uppercase',
  },
  orderShipmentContainer: {
    marginTop: '0',
  },
  shippingToString: {
    marginTop: '1rem',
    marginBottom: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  chargeItemContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  chargeListContainer: {
    marginTop: '2rem',
  },
};
