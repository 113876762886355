/**
 * The dimension is added contextual information to a recorded metric that can be used to filter or sort the data
 * in the CloudWatch metrics dashboard.
 */
export enum MetricDimension {
  ACTION = 'action',
  COUNT = 'count',
  ELEMENT = 'element',
  MARKETPLACE = 'marketplace',
  PAGE = 'page',
  TIME = 'time',
  ID = 'id',
  SESSION_ID = 'sessionId',
  MESSAGE = 'message',
  API = 'api',
}

/**
 * The method determines what we are measuring.
 */
export enum MetricMethod {
  CLICK = 'UIClick',
  PAGE_VIEW = 'UIPageView',
  PAGE_LOAD_TIME = 'UIPageLoadTime',
  ERROR = 'Error',
  CONTENT_VIEWED = 'UIContentView',
  API_ERROR = 'API_Error',
  API_REQUEST = 'API_Request',
}

/**
 * The type determines where the metric is published to in CloudWatch.
 * - Counter and Timer types will be published to the site's CloudWatch metrics dashboard.
 * - String type will be published to the CloudWatch KatalMonitoring log group.
 */
export enum MetricType {
  COUNTER = 'Counter',
  STRING = 'String',
  TIMER = 'Timer',
}
