export const quantitySelectorStyle: Record<string, React.CSSProperties> = {
  quantitySelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: 'var(--secondary-color)',
    marginBottom: '0.4rem',
    fontSize: '0.5rem',
    marginTop: 'auto',
  },
  quantitySelectorButton: {
    height: '20px',
    width: '14px',
    padding: '0 5px',
    border: '1px solid #fff',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75rem',
  },
  quantitySelectorInput: {
    width: '15px',
    height: '8px',
    padding: '6px 12px',
    borderTop: '1px solid #fff',
    borderBottom: '1px solid #fff',
    borderLeft: 0,
    borderRight: 0,
    textAlign: 'center',
    fontFamily: 'var(--font-family)',
    color: 'var(--primary-color)',
    backgroundColor: 'var(--secondary-color)',
  },
};
