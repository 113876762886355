import 'src/sites/DojaCatInternalPilot/DojaCat.css';

import { ItemIdentifier } from '@amzn/mulberry-external-typescript-client';
import { useEffect, useState } from 'react';
import Favicon from 'react-favicon';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { LOCALE_STRING } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { FavIcons } from 'src/images/icons';
import ClosePageComponent from 'src/pages/CloseWindowPage';
import { Paths } from 'src/paths/Paths';
import NavBar from 'src/sites/DojaCatInternalPilot/components/NavBar/NavBar';
import DojaCatOrderPage from 'src/sites/DojaCatInternalPilot/pages/DojaCatOrderPage';
import DojaCatProductDetailPage from 'src/sites/DojaCatInternalPilot/pages/DojaCatProductDetailPage';
import Home from 'src/sites/DojaCatInternalPilot/pages/Home';
import SuccessPage from 'src/sites/DojaCatInternalPilot/pages/SuccessPage';
import NotFoundPage from 'src/sites/TormentedValleyExternalPilot/pages/NotFoundPage';
import { selectCsrfToken } from 'src/store/authSlice';
import { selectCart } from 'src/store/cartSlice';
import { hydrateCatalog } from 'src/store/catalogSlice';
import { AppDispatch } from 'src/store/store';

export const DC_INCLUDE_SESSION_ID_IN_METRICS = false;

const DojaCat = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const csrfToken = useSelector(selectCsrfToken);

  const cart = useSelector(selectCart);
  useEffect(() => {
    const cartIdentifiers = cart?.items.map((cartItem) => {
      return {
        itemAsin: cartItem.asin,
        customId: cartItem.customId,
        customizationToken: cartItem.customizationToken,
      } as ItemIdentifier;
    });
    if (cartIdentifiers) {
      dispatch(
        hydrateCatalog({
          itemIdentifiers: cartIdentifiers,
          site: SiteType.DOJACAT,
          locale: LOCALE_STRING,
          csrfToken: csrfToken,
        }),
      );
    }
  }, [cart, csrfToken]);

  // opening the nav menu or cart drawer will auto close the other one
  useEffect(() => {
    document.title = 'Doja Cat';
    if (isNavMenuOpen) {
      setIsCartDrawerOpen(false);
    }
  }, [isNavMenuOpen]);

  useEffect(() => {
    if (isCartDrawerOpen) {
      setIsNavMenuOpen(false);
    }
  }, [isCartDrawerOpen]);

  return (
    <BrowserRouter>
      {/* ToDo: Replace this with actual Favicon URLs when we have it. This is a temporary emoji.  We can show/hide cart count */}
      <Favicon alertCount={cart?.quantity} url={FavIcons.DOJACAT} />
      <NavBar
        isCartDrawerOpen={isCartDrawerOpen}
        setIsCartDrawerOpen={setIsCartDrawerOpen}
        isNavMenuOpen={isNavMenuOpen}
        setIsNavMenuOpen={setIsNavMenuOpen}
        includeSessionIdInMetrics={DC_INCLUDE_SESSION_ID_IN_METRICS}
      />
      {(isCartDrawerOpen || isNavMenuOpen) && (
        // Clicking outside of the cart (in the overlay) will close the cart drawer
        <a
          onClick={() => {
            setIsCartDrawerOpen(false);
            setIsNavMenuOpen(false);
          }}
        >
          <div className="pageOverlay"></div>
        </a>
      )}
      <TransitionGroup>
        <CSSTransition key="group" classNames="fade" timeout={300}>
          <Routes>
            <Route path={Paths.home} Component={Home} />
            <Route path={Paths.details} Component={DojaCatProductDetailPage} />
            <Route path={Paths.previewCheckout} Component={DojaCatOrderPage} />
            <Route path={Paths.successCheckout} Component={SuccessPage} />
            <Route path={Paths.closeWindow} Component={ClosePageComponent} />
            <Route path={Paths.invalid} Component={NotFoundPage} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </BrowserRouter>
  );
};

export default DojaCat;
