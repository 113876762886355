import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/components/Button/Button';
import MessageBox from 'src/components/MessageBox/MessageBox';
import {
  CONTINUE_SHOPPING_STRING,
  DOJA_CAT_PREFIX,
  GENERIC_ORDER_ERROR_STRING,
  ORDER_STATUS_INSTRUCTION_STRING,
  TAKE_ME_HOME_STRING,
  TAKE_ME_TO_ORDERS_STRING,
  UH_OH,
  YOUR_ORDER_CONFIRMED_STRING,
} from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { recordClick } from 'src/metrics';
import { ActionType, PageType } from 'src/metricsTypes/metricsTypes';
import withPageMetrics from 'src/shared/withPageMetrics';
import { NavigationProps, withRouter } from 'src/shared/withRouter';
import { buttonCss } from 'src/sites/DojaCatInternalPilot/components/Button/ButtonCss';
import { confirmOrderErrorCss } from 'src/sites/DojaCatInternalPilot/components/MessageBox/ConfirmOrderError';
import { DC_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/DojaCatInternalPilot/DojaCat';
import { CartState, replaceCartState, selectOrderError } from 'src/store/cartSlice';
import { selectSessionId } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';

const SuccessPage = ({ params, navigate }: NavigationProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector(selectOrderError);
  const confirmOrderComplete = params?.get('confirmOrderComplete') || '';
  const sessionId = useSelector(selectSessionId);

  useEffect(() => {
    if (confirmOrderComplete && isEmpty(error)) {
      dispatch(
        replaceCartState({
          cart: null,
          previewOrderInProgress: false,
          confirmOrderInProgress: false,
        } as CartState),
      );
    }
  }, []);

  const goHome = (): void => {
    recordClick(
      SiteType.DOJACAT,
      ActionType.GO_HOME,
      DC_INCLUDE_SESSION_ID_IN_METRICS ? sessionId : undefined,
    );
    navigate(`/`);
  };

  return (
    <>
      {confirmOrderComplete && isEmpty(error) ? (
        <div className="successPageContainer" data-testid="successPage">
          <MessageBox
            heading={YOUR_ORDER_CONFIRMED_STRING}
            text={ORDER_STATUS_INSTRUCTION_STRING}
            link={confirmOrderComplete}
            linkText={TAKE_ME_TO_ORDERS_STRING}
            styles={confirmOrderErrorCss}
            testId="successPageMessageBox"
          />
          <div className="successPageButtonContainer" data-testid="successPageButtonContainer">
            <Button
              text={CONTINUE_SHOPPING_STRING}
              onClick={goHome}
              styles={buttonCss}
              classNamePrefix={DOJA_CAT_PREFIX}
            />
          </div>
        </div>
      ) : (
        <div className="errorContainer" data-testid="errorContainer">
          <MessageBox
            heading={UH_OH}
            text={GENERIC_ORDER_ERROR_STRING}
            styles={confirmOrderErrorCss}
          />
          <div>
            <Button
              text={TAKE_ME_HOME_STRING}
              onClick={goHome}
              styles={buttonCss}
              classNamePrefix={DOJA_CAT_PREFIX}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default withPageMetrics(
  SiteType.DOJACAT,
  PageType.SUCCESS_PAGE,
  withRouter(SuccessPage),
  DC_INCLUDE_SESSION_ID_IN_METRICS,
);
