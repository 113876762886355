import 'src/sites/DojaCatInternalPilot/components/ProductDetailPage/ProductDetailPage.css';

import { DOJA_CAT_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import ProductDetailPage, {
  ProductDetailPageOptions,
  ProductDetailPageStyles,
} from 'src/pages/ProductDetailPage';
import { imageBoxCss } from 'src/sites/DojaCatInternalPilot//components/ImageBox/ImageBoxCss';
import { buttonCss } from 'src/sites/DojaCatInternalPilot/components/Button/ButtonCss';
import { buttonGroupCss } from 'src/sites/DojaCatInternalPilot/components/ButtonGroup/buttonGroupCss';
import { confirmOrderErrorCss } from 'src/sites/DojaCatInternalPilot/components/MessageBox/ConfirmOrderError';
import { primaryInfoBoxCss } from 'src/sites/DojaCatInternalPilot/components/PrimaryInfoBox/PrimaryInfoBoxCss';
import { productDetailBoxCss } from 'src/sites/DojaCatInternalPilot/components/ProductDetailBox/ProductDetailBoxCss';
import {
  dcLoadingSpinnerContainer,
  dcProductDetailPageInfo,
  dcVariationLabel,
} from 'src/sites/DojaCatInternalPilot/components/ProductDetailPage/ProductDetailPageCss';
import { quantitySelectorStyle } from 'src/sites/DojaCatInternalPilot/components/QuantitySelector/QuantitySelectorCss';
import { DC_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/DojaCatInternalPilot/DojaCat';
import { tvAlertContainer } from 'src/sites/TormentedValleyExternalPilot/components/ProductDetailPage/ProductDetailPageCss';

const DojaCatProductDetailPage = () => {
  const $style: ProductDetailPageStyles = {
    loadingSpinnerContainer: dcLoadingSpinnerContainer,
    messageBoxErrorStyles: confirmOrderErrorCss,
    imageBoxStyles: imageBoxCss,
    productDetailPageInfo: dcProductDetailPageInfo,
    primaryInfoBoxStyles: primaryInfoBoxCss,
    variationLabel: dcVariationLabel,
    buttonGroupStyles: buttonGroupCss,
    quantitySelectorStyles: quantitySelectorStyle,
    buttonStyles: buttonCss,
    alertContainer: tvAlertContainer, // using tormented valley css here bc this functionality wasn't originally on doja cat
    descriptionBulletsStyle: productDetailBoxCss,
  };
  const productDetailPageOptions: ProductDetailPageOptions = {
    showLargeImage: false,
  };

  return (
    <ProductDetailPage
      siteType={SiteType.DOJACAT}
      sitePrefix={DOJA_CAT_PREFIX}
      style={$style}
      options={productDetailPageOptions}
      includeSessionIdInMetrics={DC_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};

export default DojaCatProductDetailPage;
